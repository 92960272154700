const continueSelector = '#continue';

function startGame(){
	const game = window.location.pathname;
	if(game.indexOf('game-one') !== -1){
		storeValue('game', 'game-one');
	} else if(game.indexOf('game-two') !== -1){
		storeValue('game', 'game-two');
	}
}

function initContinueButtons() {
	const continueButtons = document.querySelectorAll(continueSelector);

	continueButtons.forEach(function(continueButton) {
		continueButton.addEventListener('click', function(event) {

			// Find next game page
			var gamePage = document.querySelector('.current-page');
			var nextPage = getNextSibling(gamePage);

			// Remove current page
			var currentPage = document.querySelector('.current-page');
			currentPage.parentNode.removeChild(currentPage);

			// Apply flag to next page
			nextPage.classList.add('current-page');

			// Scroll to top
			window.scrollTo(0, 0);

			// Store answers if applicable
			if(gamePage.classList.contains('game-question')) {
				var pageQuestion = gamePage.getAttribute('apo-question');
				var pageAnswer = gamePage.getAttribute('apo-answer');
				var answerSelected = continueButton.getAttribute('apo-value');

				if(answerSelected){
					const response = {
						question: pageQuestion,
						answer: answerSelected,
						correct: pageAnswer == answerSelected
					}

					storeValue(pageQuestion, JSON.stringify(response));
				}
			}

			// Advance step indicator if allowed
			if(gamePage.classList.contains('game-advance')) {
				// Find the next step indicator
				var stepIndicator = document.querySelector('.current-step');
				var nextStep = getNextSibling(stepIndicator);

				// Remove current flag from current step indicator
				var currentStep = document.querySelector('.current-step');
				currentStep.classList.remove('current-step');

				// Apply flag to next step indicator
				nextStep.classList.add('current-step');
			}

			if(nextPage.classList.contains('game-submit')) {
				scoreGame();
			}

			if(nextPage.classList.contains('game-results')) {
				clearLocalStorage();
			}
			
		});
	});

}

document.addEventListener('DOMContentLoaded', function() {
  startGame();
  initContinueButtons();
});

var getNextSibling = function (elem, selector) {

	// Get the next sibling element
	var sibling = elem.nextElementSibling;

	// If there's no selector, return the first sibling
	if (!selector) return sibling;

	// If the sibling matches our selector, use it
	// If not, jump to the next sibling and continue the loop
	while (sibling) {
		if (sibling.matches(selector)) return sibling;
		sibling = sibling.nextElementSibling
	}

};

function getValue(key){
	return window.localStorage.getItem(key);
}

function storeValue(key, value){
	window.localStorage.setItem(key, value);
}

function clearLocalStorage () {
	window.localStorage.clear();
}

function scoreGame(){
	const game = getValue('game');
	let gameLength = 0;
	let tally = 0;
	if(game === "game-one"){
		gameLength = 6;
	} else {
		gameLength = 5;
	}

	for(var i=1; i<=gameLength; i++){
		let response = getValue(i);
		response = JSON.parse(response);
		if(response.correct){
			tally++;
		}
		storeValue(i, response.answer);
	}
	storeValue("score", tally);
}